import { createApp } from 'vue'
import App from './App.vue'
import NotFound from './NotFound.vue'
import router from './router'


const app = createApp({
    data() {
        return {
            isValidRoute: false // 정상적인 라우트 여부를 체크할 상태
        }
    },
    methods: {
        checkValidRoute() {
            // 실제로는 라우팅 로직이나 다른 방식으로 정상적인 라우트 여부를 체크
            const validRoutes = ['/', '/company', '/team'];
            this.isValidRoute = validRoutes.includes(window.location.pathname);
        }
    },
    mounted() {
      this.checkValidRoute(); // 초기에 한 번 호출
      window.addEventListener('popstate', this.checkValidRoute); // 브라우저 뒤로가기 등에 대응
    },
    unmounted() {
      window.removeEventListener('popstate', this.checkValidRoute); // 이벤트 리스너 제거
    },
    render() {
        return (
            <div id="app">
                {this.isValidRoute ? <App /> : <NotFound />}
            </div>
        );
    }
});

app.component('app', App);
app.component('not-found', NotFound);
app.use(router) 
app.mount('#app')

<template>
    <footer class="flex flex-col items-center w-full pt-8 lg:pt-12 pb-8 bg-white-footer">
        <div class="w-full px-5 sm:px-8 xl:w-1200 xl:px-0">
            <div class="border-b border-[#D9D9D9] lg:pb-10 lg:flex lg:items-center lg:justify-between">
                <div class="text_group lg:flex lg:items-center">
                    <div class="logo_group flex flex-col items-center">
                        <div class="logo pb-1 w-[93px] sm:w-auto">
                            <img src="../assets/img/logo/logo_whooper.png" alt="">
                        </div>
                        <p class="pb-2 font-semibold sm:font-bold text-[15px] sm:text-lg">후퍼(주)</p>
                    </div>
                    <div class="flex flex-col items-center lg:items-start lg:pl-16 pb-4 lg:pb-0 font-semibold lg:font-bold text-[12px] sm:text-[15px] text-gray lg:leading-[28px]">
                        <span>사업자 등록번호 : 119-86-31214</span>
                        <span>주소 : 서울 금천구 벚꽃로 298, 대륭포스트타워 6차 806호</span>
                        <span>대표 : 박지은 | TEL : 02.875.2134 | FAX : 02.884.2133</span>
                        <span class="flex items-center">
                            <img src="../assets/img/icon/letter.svg" alt="" class="pr-1 w-4 sm:w-auto">
                            support@whoopersoft.com
                        </span>
                    </div>
                </div>
                <div class="link_group flex justify-center pb-9 lg:pb-0">
                    <a href="https://www.jobploy.kr" target="_blank" class="relative flex flex-col items-center px-2 text-[12px] font-bold">
                        <div class="after:content-['잡플로이'] after:absolute after:bottom-[-20px] bg-gray hover:bg-dark-gray w-[40px] lg:w-[50px] h-[40px] lg:h-[50px] text-gray hover:text-dark-gray rounded-full flex items-center justify-center">
                            <img class="w-[26px] lg:w-auto" src="../assets/img/icon/jobploy_icon.svg" alt="">
                        </div>
                    </a>
                    <a href="https://www.payat.co.kr/" target="_blank" class="relative flex flex-col items-center px-2 text-[12px] font-bold">
                        <div class="after:content-['페이앳'] after:absolute after:bottom-[-20px] bg-gray hover:bg-dark-gray w-[40px] lg:w-[50px] h-[40px] lg:h-[50px] text-gray hover:text-dark-gray rounded-full flex items-center justify-center">
                            <img class="w-[26px] lg:w-auto" src="../assets/img/icon/payat_icon.svg" alt="">
                        </div>
                    </a>
                    <a href="http://www.mangotalks.net/" target="_blank" class="relative flex flex-col items-center px-2 text-[12px] font-bold">
                        <div class="after:content-['망고톡'] after:absolute after:bottom-[-20px] bg-gray hover:bg-dark-gray w-[40px] lg:w-[50px] h-[40px] lg:h-[50px] text-gray hover:text-dark-gray rounded-full flex items-center justify-center">
                            <img class="w-[24px] lg:w-auto" src="../assets/img/icon/mangotalk_icon.svg" alt="">
                        </div>
                    </a>
                </div>
            </div>
            <p class="text-gray font-semibold pt-4 lg:pt-8 text-center lg:text-right text-[12px]">COPYRIGHT © WHOOPER. ALL RIGHT RESERVED.</p>
        </div>
    </footer>
</template>

<script>
export default {
  name: 'PageFooter',
  props: {
    msg: String
  }
}
</script>
<template>
  <div @click="closeMenu">
    <NavBar @closeMenu="closeMenu" @toggleMenu="toggleMenu" :isMenuOpen="isMenuOpen"/>
    <router-view />
    <PageFooter />
  </div>  
</template>

<script>
import NavBar from './components/NavBar.vue'
import PageFooter from './components/PageFooter.vue'

import './styles/app.css'

export default {
  name: 'App',
  components: {
    NavBar,
    PageFooter,
  },
  data() {
    return {
      isMenuOpen: false,
    };
  },
  methods: {
    closeMenu() {
      // 여기에 메뉴를 닫는 구체적인 로직을 추가
      this.isMenuOpen = false;
    },
    toggleMenu() {
      // 메뉴를 열거나 닫음
      this.isMenuOpen = !this.isMenuOpen;
    },
  },
}
</script>

<style>
@import url('https://webfontworld.github.io/pretendard/Pretendard.css');
@import url('https://webfontworld.github.io/gmarket/GmarketSans.css');
</style>

<template>
    <nav :class="{ 'bg-white/75' : showNavBg }" class="navbar fixed flex justify-center w-full z-50">
        <div class="w-full px-5 sm:px-8 xl:w-1200 xl:px-0 flex justify-between items-center mx-auto py-4">
            <router-link to="/" class="logo">
                <img class="w-[96px] sm:w-auto" src="../assets/img/logo/logo_whooper.png" alt="">
            </router-link>
            <div class="menu hidden xl:block relative bg-black/[.35] px-[23px] py-2 rounded-full text-white text font-bold">
                <ul class="flex">
                    <li @mouseover="handleHover('company')" @mouseout="handleMouseOut('company')">
                        <router-link class="px-[49px] py-2 cursor-pointer" to="/company">회사소개</router-link>
                    </li>
                    <li @mouseover="handleHover('team')" @mouseout="handleMouseOut('team')">
                        <router-link class="px-[49px] py-2 cursor-pointer" to="/team">팀 문화</router-link>
                    </li>
                    <li @mouseover="handleHover('recruit')" @mouseout="handleMouseOut('recruit')">
                        <a class="px-[49px] py-2 cursor-pointer" target="_blank" href="https://www.jobkorea.co.kr/Recruit/GI_Read/43632655?Oem_Code=C1">채용공고</a>
                    </li>
                </ul>
                <span :class="[getClassForCurrentRoute() , { 'nav_company' : isHovered_company, 'nav_team' : isHovered_team, 'nav_recruit' : isHovered_recruit }]" class="transition duration-150 bg-black/50 block absolute w-[200px] h-10 top-0 left-0 rounded-full z-[-1]"></span>
            </div>
            <div @click.stop class="mobile_menu relative xl:hidden">
                <div @click="toggleMenu" class="cursor-pointer flex flex-col justify-between w-[27px] h-[19px]">
                    <span :class="{'rotate-45 translate-y-[8px]' : isMenuOpen}" class="block bg-black h-[3px] w-full transition duration-150 rounded-[10px]"></span>
                    <span :class="{'hidden' : isMenuOpen}" class="block bg-black h-[3px] w-full transition duration-150 rounded-[10px]"></span>
                    <span :class="{'rotate-[-45deg] translate-y-[-8px]' : isMenuOpen}" class="block bg-black h-[3px] w-full transition duration-150 rounded-[10px]"></span>
                </div>
                <ul v-if="isMenuOpen" class="absolute bg-white w-[91px] top-[27px] right-0 drop-shadow-[0_4px_4px_rgba(0,0,0,0.25)] rounded-[10px] text-[17px] font-semibold py-3">
                    <li :class="mobileMenu('/company')" @click="closeMenu" class="px-4 py-3 hover:bg-gray focus:bg-gray hover:text-white focus:text-white">
                        <router-link to="/company">회사소개</router-link> 
                    </li>
                    <li :class="mobileMenu('/team')" @click="closeMenu" class="px-4 py-3 hover:bg-gray focus:bg-gray hover:text-white focus:text-white">
                        <router-link to="/team">팀 문화</router-link>
                    </li>
                    <li @click="closeMenu" class="px-4 py-3 hover:bg-gray focus:bg-gray hover:text-white focus:text-white">
                        <a target="_blank" href="https://www.jobkorea.co.kr/Recruit/GI_Read/43632655?Oem_Code=C1">채용공고</a>
                    </li>
                </ul>  
            </div>       
        </div>
    </nav>
</template>

<script>
export default {
    props: {
        isMenuOpen: Boolean,
    }, 
    data() {
        return {
            isHovered_company: false,
            isHovered_team: false,
            isHovered_recruit: false,
            showNavBg:false,
        };
    },
    methods: {
        handleHover(item) {
            switch (item) {
                case 'company':
                    this.isHovered_company = true;
                    break;
                case 'team':
                    this.isHovered_team = true;
                    break;
                case 'recruit':
                    this.isHovered_recruit = true;
                    break;
            }
        },
        handleMouseOut(item) {
            switch (item) {
                case 'company':
                    this.isHovered_company = false;
                    break;
                case 'team':
                    this.isHovered_team = false;
                    break;
                case 'recruit':
                    this.isHovered_recruit = false;
                    break;
            }
        },
        getClassForCurrentRoute() {
            return {
                'hidden': this.$route.path === '/',
                'translate-x-0': this.$route.path === '/company',
                'translate-x-[148px]': this.$route.path === '/team',
            };
        },
        mobileMenu(routePath) {
            return {
                'bg-gray': this.$route.path === routePath,
                'text-white': this.$route.path === routePath,
            };
        },
        mobileMenuClass() {
            return {
                'hidden': this.$route.path === '/',
                'translate-x-0': this.$route.path === '/company',
                'translate-x-[148px]': this.$route.path === '/team',
            };
        },
        handleScroll() {
            this.showNavBg = window.scrollY > 100;
        },
        toggleMenu(event) {
            this.$emit("toggleMenu", event);
        },
        closeMenu(event) {
            this.$emit("closeMenu", event);
        },
    },
    mounted() {
        // 스크롤 이벤트 리스너 추가
        window.addEventListener('scroll', this.handleScroll);

        // 페이지가 로드되면 바로 실행
        this.handleScroll();
    },
    beforeUnmount() {
        // 컴포넌트가 언마운트되기 전에 이벤트 리스너 제거
        window.removeEventListener('scroll', this.handleScroll);
    },
};
</script>
import { createRouter, createWebHistory } from 'vue-router';

const routes = [
    {
        path: "/",
        component: () => import("@/views/MainPage.vue")
    },
    {
        path: "/company",
        component: () => import("@/views/CompanyPage.vue")
    },
    {
        path: "/team",
        component: () => import("@/views/TeamPage.vue")
    }
];

const router = createRouter({
    history: createWebHistory(),
    routes,
    scrollBehavior(to, from, savedPosition) {
        // 스크롤을 저장한 위치로 이동하거나 맨 위로 이동
        return savedPosition || { top: 0 };
    },
});

export default router;